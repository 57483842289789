import { createSlice } from "@reduxjs/toolkit";

import { getAllShopAction, getAllOwnerAction } from "../../actions/shops/shopAction";

import { toast } from 'react-toastify';

const INITIAL_STATE = {
    shop: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    owner: {
        data: {
            records: null,
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
}


const shopReducer = createSlice({
    name: "Shop",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetTillDetailsState(state, action) {
        },
    },
    extraReducers: (builder) => {



        // get all shop action start


        builder.addCase(getAllShopAction.fulfilled, (state, action) => {
            state.shop.data.records = action.payload.data;
            state.shop.apiMsg.status = action.meta.requestStatus;
            state.shop.apiMsg.message = "success";
        });
        builder.addCase(getAllShopAction.pending, (state, action) => {
            state.shop.data.records = null;
            state.shop.apiMsg.message = action.meta.requestStatus;
            state.shop.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllShopAction.rejected, (state, action) => {
            state.shop.apiMsg.message = action.error.message;
            state.shop.apiMsg.status = action.meta.requestStatus;
        });

        // get all shop action end

        //------------------------------- getAllOwnerAction start -------------------------

        builder.addCase(getAllOwnerAction.fulfilled, (state, action) => {
            state.owner.data.records = action.payload.data;
            state.owner.apiMsg.status = action.meta.requestStatus;

        });
        builder.addCase(getAllOwnerAction.pending, (state, action) => {
            state.owner.data.records = null;
            state.owner.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllOwnerAction.rejected, (state, action) => {
            state.owner.apiMsg.status = action.meta.requestStatus;
        });

        //------------------------------- getAllOwnerAction start -------------------------

    },
});
export const { } = shopReducer.actions;
export default shopReducer.reducer;