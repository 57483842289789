import React from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import WithLoading from './app/components/shared/WithLoading';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { ReducerStateContant } from "./app/helper/constants/general-const";
import RouteLoader from "./app/components/shared/RouteLoader";

const Layout = WithLoading(React.lazy(() => import("./app/components/shared/Layout")));


const PageNotFoundPage = WithLoading(React.lazy(() => import("./app/components/PageNotFound")));

const AccessDeniedPage = WithLoading(React.lazy(() => import("./app/components/shared/AccessDenied")));
// pages with loading
const LoginPage = WithLoading(React.lazy(() => import("./app/components/Login")));

const OwnerPage = WithLoading(React.lazy(() => import("./app/components/owner/owner")));
const DashboardPage = WithLoading(React.lazy(() => import("./app/components/dashboard/dashboard")));

const ShopPage = WithLoading(React.lazy(() => import("./app/components/shops/shop")));

const BusinessPage = WithLoading(React.lazy(() => import("./app/components/owner/business")));

const SupplierPage = WithLoading(React.lazy(() => import("./app/components/owner/supplier")));

const RequireAuth = (props) => {
  return <> {props.active ? props.children : <Navigate to="/auth/login" replace />} </>;
};


const AppRoutes = (props) => {

  const protectedLayout = (
    <>
      {
        <>
          {

            props.authorizedAccess == ReducerStateContant.pending ?
              <RouteLoader />
              :
              <RequireAuth active={props.active}>
                {isMobile ? <MobileView className="device-mob"> <Layout /> </MobileView> : <BrowserView className="device-desk"> <Layout /> </BrowserView>}
              </RequireAuth>
          }
        </>

      }
    </>
  );

  return (

    <Routes>



      <Route path="/dashboard" element={protectedLayout}>
        <Route index element={<DashboardPage />} />
      </Route>

      <Route path="/Owner" element={protectedLayout}>
        <Route index element={<OwnerPage />} />
      </Route>

      <Route path="/Owner" element={protectedLayout}>
        <Route path="business" element={<BusinessPage />} />
        <Route path="supplier" element={<SupplierPage />} />

      </Route>



      <Route path="/Shop" element={protectedLayout}>
        <Route index element={<ShopPage />} />
      </Route>

      <Route path="/auth" >
        <Route path="login" element={<LoginPage />} />
      </Route>

      <Route path="/accessdenied" element={protectedLayout} >
        <Route index element={<AccessDeniedPage />} />
      </Route>

      <Route path="*" element={protectedLayout}>
        <Route path="*" element={<PageNotFoundPage />} />
      </Route>

    </Routes>

  );
};

export default AppRoutes;