import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";

export const getAllShopAction = createAsyncThunk(

  "Shop/GetAllShop",
  async (data) => {
    var apiUrl = data ? "user/getAllBusiness?id=" + data : "user/getAllBusiness"
    return apiCall(apiUrl, "GET", {}, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getAllOwnerAction = createAsyncThunk(

  "Shop/GetAllOwner",
  async (data) => {
    return apiCall("user/getallowners", "GET", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
