import { createSlice } from "@reduxjs/toolkit";
import { getAllSupplierAction, getSupplierAction, getSupplierTypeAction, insertUpdateSupplierAction } from "../../actions/supplier/supplerAction";
import { toast } from 'react-toastify';

const INITIAL_STATE = {
    supplier: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedSupplier: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    supplierList: {
        data: {
            records: null,
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        }
    },
    insertUpdateDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
};
const supplierReducer = createSlice({
    name: "Supplier",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetSupplierDetailsState(state, action) {
            state.selectedSupplier = INITIAL_STATE.selectedSupplier;
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
            state.supplierList = INITIAL_STATE.supplierList;

        },
    },
    extraReducers: (builder) => {
        // getAllSupplierAction start
        builder.addCase(getAllSupplierAction.fulfilled, (state, action) => {
            state.supplier.data.records = action.payload.data;
            state.supplier.apiMsg.status = action.meta.requestStatus;
            state.supplier.apiMsg.message = "success";
        });
        builder.addCase(getAllSupplierAction.pending, (state, action) => {
            state.supplier.data.records = null;
            state.supplier.apiMsg.message = action.meta.requestStatus;
            state.supplier.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSupplierAction.rejected, (state, action) => {
            state.supplier.apiMsg.message = action.error.message;
            state.supplier.apiMsg.status = action.meta.requestStatus;
        });

        //getAllSupplierAction end

        //------------------------------- getSupplierAction start -------------------------

        builder.addCase(getSupplierAction.fulfilled, (state, action) => {
            state.selectedSupplier.data = action.payload.data;
            state.selectedSupplier.apiMsg.status = action.meta.requestStatus;
            state.selectedSupplier.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getSupplierAction.pending, (state, action) => {
            state.selectedSupplier.data = null;
            state.selectedSupplier.apiMsg.message = action.meta.requestStatus;;
            state.selectedSupplier.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getSupplierAction.rejected, (state, action) => {
            state.selectedSupplier.apiMsg.message = action.error.message;
            state.selectedSupplier.apiMsg.status = action.meta.requestStatus;
        });


        //   getSupplierAction end

        //------------------------------- getSupplierTypeAction start -------------------------

        builder.addCase(getSupplierTypeAction.fulfilled, (state, action) => {
            state.supplierList.data.records = action.payload.data;
            state.supplierList.apiMsg.status = action.meta.requestStatus;

        });
        builder.addCase(getSupplierTypeAction.pending, (state, action) => {
            state.supplierList.data.records = null;
            state.supplierList.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getSupplierTypeAction.rejected, (state, action) => {
            state.supplierList.apiMsg.status = action.meta.requestStatus;
        });

        //------------------------------- getSupplierTypeAction start -------------------------

        //insertUpdateSupplierAction Action start

        builder.addCase(
            insertUpdateSupplierAction.fulfilled,
            (state, action) => {

                state.insertUpdateDetails.data = action.payload.data.data;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                    let updatedRecord = state.supplier.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.supplier.data.records = updatedRecord;
                }
                else {
                    if (state.supplier.data.records.length) {
                        state.supplier.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.supplier.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdateSupplierAction.pending,
            (state, action) => {
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateSupplierAction.rejected,
            (state, action) => {
                state.insertUpdateDetails.apiMsg.message = action.error.message;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );
        //   insertUpdateSupplierAction end

    },
});
export const { RESET, ResetSupplierDetailsState } = supplierReducer.actions;
export default supplierReducer.reducer;

