import { createSlice } from "@reduxjs/toolkit";

import { getAllOwnerAction, insertUpdateOwnerAction, getAllTenantData, getCountryAction } from "../../actions/owners/ownerActions";

import { toast } from 'react-toastify';

const INITIAL_STATE = {
  owner: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },

  insertUpdateDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },

  tenant: {
    data: {
      records: null,
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },

  selectedCountry: {
    data: {
      records: null,
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  },

};



const ownerReducer = createSlice({
  name: "Owner",
  initialState: INITIAL_STATE,
  reducers: {

    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetOwnerDetailsState(state, action) {
      state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
      state.tenant = INITIAL_STATE.tenant;
    },
  },
  extraReducers: (builder) => {
    // get all owner action start
    builder.addCase(getAllOwnerAction.fulfilled, (state, action) => {
      state.owner.data.records = action.payload.data;
      state.owner.apiMsg.status = action.meta.requestStatus;
      state.owner.apiMsg.message = "success";
      // not working above  line show error
    });
    builder.addCase(getAllOwnerAction.pending, (state, action) => {
      // console.log("getAllOwnerAction pending", action);
      state.owner.data.records = null;
      state.owner.apiMsg.message = action.meta.requestStatus;
      state.owner.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllOwnerAction.rejected, (state, action) => {
      // console.log("getAllOwnerAction rejected", action);
      state.owner.apiMsg.message = action.error.message;
      state.owner.apiMsg.status = action.meta.requestStatus;
    });

    // get all owner action end




    //insertUpdateOwnerAction Action start

    builder.addCase(
      insertUpdateOwnerAction.fulfilled,
      (state, action) => {

        state.insertUpdateDetails.data = action.payload.data.data;
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateDetails.apiMsg.message = action.payload.message.message;
        // console.log("state.insertUpdateOwnerAction.data", state.insertUpdateOwnerAction.data)

        if (!action.payload.newRecord) {
          let updatedRecord = state.owner.data.records.map(x => {
            if (x.id == action.payload.data.data.id) {
              return action.payload.data.data;
            }
            else {
              return x;
            }
          })

          state.owner.data.records = updatedRecord;
        }
        else {
          if (state.owner.data.records.length) {
            state.owner.data.records.unshift(action.payload.data.data);
          }
          else {
            state.owner.data.records.push(action.payload.data.data);
          }
        }
        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });

      }
    );
    builder.addCase(
      insertUpdateOwnerAction.pending,
      (state, action) => {
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      insertUpdateOwnerAction.rejected,
      (state, action) => {
        //   console.log("insertUpdateOwnerAction rejected", action);
        state.insertUpdateDetails.apiMsg.message = action.error.message;
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });
      }
    );


    //   insert update till action end



    builder.addCase(getAllTenantData.fulfilled, (state, action) => {
      state.tenant.data.records = action.payload.data;
      state.tenant.apiMsg.status = action.meta.requestStatus;

    });
    builder.addCase(getAllTenantData.pending, (state, action) => {
      state.tenant.data.records = null;
      state.tenant.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllTenantData.rejected, (state, action) => {
      state.tenant.apiMsg.status = action.meta.requestStatus;
    });



    //------------------------------- getCountryAction start -------------------------

    builder.addCase(getCountryAction.fulfilled, (state, action) => {
      state.selectedCountry.data.records = action.payload.data;
      state.selectedCountry.apiMsg.status = action.meta.requestStatus;

    });
    builder.addCase(getCountryAction.pending, (state, action) => {
      state.selectedCountry.data.records = null;
      state.selectedCountry.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getCountryAction.rejected, (state, action) => {
      state.selectedCountry.apiMsg.status = action.meta.requestStatus;
    });

    //------------------------------- getCountryAction start -------------------------

  },
});
export const { RESET, ResetOwnerDetailsState } = ownerReducer.actions;
export default ownerReducer.reducer;