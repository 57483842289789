import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import AppRoutes from './app.routes';
import AuthRoutes from './auth.routes';
import { ReducerStateContant } from "./app/helper/constants/general-const";
// import { getAllActiveBusinessData } from "./app/redux-store/actions/masterDataActions";




const App = () => {
  const dispatch = useDispatch();
  const  {isLoggedIn}  = useSelector((state) => state.auth);
  const [authorizedAccess, setAuthorizedAccess] = useState(ReducerStateContant.pending);

  useEffect(() => {
    if (isLoggedIn) {
      setAuthorizedAccess(ReducerStateContant.fulfilled)
    }

  }, [isLoggedIn]);


  
  return (
    <>
     {
        isLoggedIn ? <AppRoutes authorizedAccess={authorizedAccess} active={isLoggedIn} /> : <AuthRoutes />
      }
    
    </>
  );
};

export default App;
