import { createSlice } from "@reduxjs/toolkit";

import { getAllBusinessAction, insertUpdateBusinessAction } from "../../actions/business/businessAction";

import { toast } from 'react-toastify';

const INITIAL_STATE = {
  business: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },

};
const businessReducer = createSlice({
  name: "Business",
  initialState: INITIAL_STATE,
  reducers: {

    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetBusinessDetailsState(state, action) {
      state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
    },
  },
  extraReducers: (builder) => {
    // getAllBusinessAction start
    builder.addCase(getAllBusinessAction.fulfilled, (state, action) => {
      state.business.data.records = action.payload.data;
      state.business.apiMsg.status = action.meta.requestStatus;
      state.business.apiMsg.message = "success";
    });
    builder.addCase(getAllBusinessAction.pending, (state, action) => {
      state.business.data.records = null;
      state.business.apiMsg.message = action.meta.requestStatus;
      state.business.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllBusinessAction.rejected, (state, action) => {
      state.business.apiMsg.message = action.error.message;
      state.business.apiMsg.status = action.meta.requestStatus;
    });

    //getAllBusinessAction end


    //insertUpdateBusinessAction Action start

    builder.addCase(
      insertUpdateBusinessAction.fulfilled,
      (state, action) => {

        state.insertUpdateDetails.data = action.payload.data.data;
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

        if (!action.payload.newRecord) {
          let updatedRecord = state.business.data.records.map(x => {
            if (x.id == action.payload.data.data.id) {
              return action.payload.data.data;
            }
            else {
              return x;
            }
          })

          state.business.data.records = updatedRecord;
        }
        else {
          if (state.business.data.records.length) {
            state.business.data.records.unshift(action.payload.data.data);
          }
          else {
            state.business.data.records.push(action.payload.data.data);
          }
        }
        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });

      }
    );
    builder.addCase(
      insertUpdateBusinessAction.pending,
      (state, action) => {
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      insertUpdateBusinessAction.rejected,
      (state, action) => {
        state.insertUpdateDetails.apiMsg.message = action.error.message;
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });
      }
    );
    //   insertUpdateBusinessAction end
  },
});
export const { RESET, ResetBusinessDetailsState } = businessReducer.actions;
export default businessReducer.reducer;