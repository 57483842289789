import { combineReducers } from 'redux'
import authReducer from "./authReducer";
import ownerReducer from "./owners/ownerReducer";
import shopReducer from "./shops/shopReducer";
import businessReducer from './business/businessReducer';
import supplierReducer from './supplier/supplierReducer';


const reducer = {
  auth: authReducer,
  ownerReducer,
  shopReducer,
  businessReducer,
  supplierReducer
}

const appReducer = combineReducers(reducer);

const rootReducer = (state, action) => {

  if (action.type === 'auth/logout/fulfilled') {

    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}


export default rootReducer;
// export default combineReducers(reducer);

export function CLEARSTORE() {
  return {
    type: "CLEARSTORE"
  };
}